/* You can add global styles to this file, and also import other style files */
$primary: #527636;
$secondary: #3f5f2d;
$background-color: #84aa40;
$table-bg-type: $secondary;
$background-grey: #f1f1f1;
$btn-padding-y: 0.2rem;

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "bootstrap-icons/font/bootstrap-icons.css";

@font-face {
  font-family: 'Gotham Pro';
  src: url('assets/fonts/GothamPro-Bold/GothamPro-Bold.woff2') format('woff2'),
  url('assets/fonts/GothamPro-Bold/GothamPro-Bold.woff') format('woff');
  font-weight: 700;
  /* Additional font properties (e.g., font-weight, font-style) can be defined here */
}

@font-face {
  font-family: 'Gotham Pro';
  src: url('assets/fonts/Gotham Pro Black/Gotham Pro Black.ttf') format('truetype');
  font-weight: 800;
}

/*html, body, .navbar, .modal-content {
  //background-color: #f8f8f8;
  background-color: $background-color;
  //background-color: #2e860d;
}*/

body {
  color: $light;
}

.body {
  //min-height: 71.8vh;
  min-height: 60vh;
}

.bg-background {
  background-color: $background-color;
}

.bg-grey {
  background-color: $background-grey;
}

html {
  font-size: 22px;
}

//body, .modal-content{
body {
  background-image: url('assets/img/background.png');
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-size: cover; /* Scale the image to cover the entire background */
  background-attachment: fixed; /* Fix the background position, so it doesn't scroll */
}

.nft, .nft .btn {
  font-size: 17px;
}

.custom-border-1 {
  border-width: 4px !important;
  border-radius: 26px !important;
}

.custom-border-2 {
  border-width: 4px !important;
  border-radius: 23px !important;
}

.custom-border-3 {
  border-width: 4px !important;
  border-radius: 20px !important;
}

.modal-body {
  background-color: $background-grey;
  border-radius: 6px;
}

.modal-footer, .modal-header {
  background-color: $background-grey;
  border-width: 0;
}

.btn-primary {
  color: white;
}

.card img, .no-border-img {
  //width: 150px !important;
  transform: scale(1.05);

}

.div-img-card {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
}

.btn-form {
  background-color: white;
}

.form-control {
  border: 0;
}

.header-title {
  font-size: 2.65rem;
  font-family: 'Gotham Pro', Arial, serif;
  font-weight: 800;
}

.header-subtitle {
  font-size: 1.32rem;
  font-family: 'Gotham Pro', Arial, serif;
  font-weight: 700;
}

.header-close-btn {
  border-width: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 1.75rem;
  top: 0.75rem;
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  font-size: 25px;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1442px;
}

.purchase-container {
  max-width: 1549px !important;
}

.country-div img {
  max-height: 95px;
}

.modal-text{
  font-size: 18px;
}


/* Override the default Bootstrap striped row colors */
.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: $primary; /* Replace with your desired color */
  color: white;
}

.table > :not(caption) > * > * {
  background-color: $secondary; /* Replace with another desired color */
  color: white;

}

td {
  vertical-align: middle;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  //background: #000000;
  border-radius: 0px;
  outline: none;
  padding: 0;
  margin: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  transform: translate(0, 4px);
  background: url('assets/img/thumb.png') center center no-repeat;
  background: #85aa40;
  background-size: 25px 25px;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  margin-top: -10px;
  cursor: pointer;
}
